<template>
  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Nuovo Progetto</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogNew')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form
        @submit="submit"
        v-model="formValid"
        ref="form_data"
        autocomplete="off"
        lazy-validation
      >
        <v-card>
          <v-card-text class="pa-6 pt-3 text-center font-size-root">
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Codice Commessa</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="requiredRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Codice Commessa"
              autocomplete="off"
              v-model="codiceCommessa"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Nome</label
            >
            <v-text-field
              hide-details="auto"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Nome"
              autocomplete="off"
              v-model="nome"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Codice Cliente</label
            >
            <v-text-field
              hide-details="auto"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Codice Cliente"
              autocomplete="off"
              v-model="codiceCliente"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Descrizione</label
            >
            <v-textarea
              hide-details="auto"
              outlined
              auto-grow
              rows="2"
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Descrizione"
              autocomplete="off"
              v-model="descrizione"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-textarea>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-1
              "
              color="primary"
              type="submit"
              >Crea</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiProgetti from "@/js/pages/progetti";
import { nomeRules, requiredRules } from "@/js/validationRules";

export default {
  name: "newProgetto",
  data() {
    return {
      token: this.$cookies.get("token_goldencar"),
      this_ruolo: this.$store.state.ruolo,
      email: "",
      nome: "",
      codiceCommessa: "",
      codiceCliente: "",
      descrizione: "",
      formValid: false,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      loading: false,
    };
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      if(!this.loading) {
        this.loading = true;
        if (this.$refs.form_data.validate() && this.formValid) {
          let data = {
            nome: this.nome,
            codiceCommessa: this.codiceCommessa,
            codiceCliente: this.codiceCliente,
            descrizione: this.descrizione,
          };
          let newProgetto = await apiProgetti.insertNew(this.token, data);
          if (newProgetto) this.$emit("chiudiDialogNew");
          else alert("Errori durante la creazione, ricontrolla i dati");
        }
        this.loading = false;
      }
    },
  },
};
</script>
