<template>
  <v-container class="listProgetti">
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom">
        <p class="font-weight-600 text-h3 text-typo mb-0">Lista Progetti</p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          no-data-text="Ancora nessun dato"
          :headers="headers"
          :items="progetti"
          :items-per-page="itemsPerPage"
          :page="page"
          :search="search"
          hide-default-footer
          class="table thead-light"
          mobile-breakpoint="0"
          :item-class="
              () => {
                return 'pointer';
              }
            "
          @click:row="apriProgetto"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Cerca"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <!--v-col cols="5" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-select
                    :items="['Tutti', 'Amministratore', 'Operatore']"
                    v-model="filterRuolo"
                    dense
                    flat
                    filled
                    solo
                    hide-details
                    height="43"
                    placeholder="Ruolo"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
											text-white
										"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-filter</v-icon
                      >
                    </template>
                  </v-select>
                </v-col-->
              </v-row>

              <v-spacer></v-spacer>

              <v-btn
                elevation="0"
                :ripple="false"
                @click="dialogNew = true"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-primary
                  py-3
                  px-6
                  rounded-sm
                  ms-3
                "
                color="primary"
                >Aggiungi</v-btn
              >
            </v-toolbar>
          </template>
          <!--template v-slot:item.azioni="{ item }">
            <v-btn
              :to="'/schedaProgetto?_id=' + item['_id']"
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-info
                me-2
                my-2
                rounded-sm
              "
              color="info"
            >
              <v-icon size="12">fas fa-eye</v-icon>
            </v-btn>
            <v-btn
              v-if="ruolo === 'Amministratore'"
              @click="progettoClicked = item;dialogModificaProgetto = true"
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-warning
                me-2
                my-2
                rounded-sm
              "
              color="#fb6340"
            >
              <v-icon size="12">ni-ruler-pencil</v-icon>
            </v-btn>
          </template-->
        </v-data-table>
      </v-card-text>
      <div class="pa-2 d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="primary"
          v-model="page"
          :length="numberOfPages"
          circle
        ></v-pagination>
      </div>
    </v-card>
    <v-dialog
      v-model="dialogNew"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <NewProgetto v-if="dialogNew" @chiudiDialogNew="chiudiDialogNew" />
    </v-dialog>
    <v-dialog
      v-model="dialogModificaProgetto"
      content-class="position-absolute top-0"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
    >
      <ModalModificaProgetto v-if="dialogModificaProgetto" :progettoProp="progettoClicked" @chiudiDialogModificaProgetto="chiudiDialogModificaProgetto" />
    </v-dialog>
  </v-container>
</template>

<script>
import apiProgetti from "@/js/pages/progetti";
import NewProgetto from "@/components/NewProgetto.vue";
import ModalModificaProgetto from "@/components/ModalModificaProgetto.vue";

export default {
  name: "listProgetti",
  components: {
    NewProgetto,
    ModalModificaProgetto
  },
  data() {
    return {
      progetti: [],
      token: this.$cookies.get("token_goldencar"),
      ruolo: this.$store.state.ruolo,
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
          width: "30%",
        },
        { text: "Codice Commessa", value: "codice_commessa", width: "20%" },
        { text: "Codice Cliente", value: "codice_cliente", width: "20%" },
        { text: "Descrizione", value: "descrizione", width: "25%" },
      ],
      //filterRuolo: undefined,
      dialogNew: false,
      dialogModificaProgetto: false,
      progettoClicked: undefined,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.progetti.length / this.itemsPerPage);
    },
  },
  watch: {
    async filterRuolo() {
      if (this.filterRuolo === "Tutti")
        this.progetti = await apiProgetti.getAll(this.token);
      else this.progetti = await apiProgetti.getAll(this.token, this.filterRuolo);
    },
  },
  async created() {
    await this.reloadLista();
  },
  methods: {
    async chiudiDialogScheda(refresh = false) {
      if (refresh) await this.reloadLista();
      this.dialogScheda = false;
    },
    async chiudiDialogNew() {
      await this.reloadLista();
      this.dialogNew = false;
    },
    async reloadLista() {
      let progetti = await apiProgetti.getAll(this.token, this.filterRuolo);
      //Array.prototype.push.apply(progetti, progetti);
      this.progetti = progetti;
    },
    async editUtente(item) {
      this.isEditingUtente = true;
      this.idUtenteClicked = item["_id"];
      this.dialogScheda = true;
    },
    async removeUtente(id_utente) {
      if (confirm("Eliminare l'utente?")) {
        let data = { _id: id_utente };
        await apiProgetti.deleteOne(this.token, data);
        this.reloadLista();
      }
    },
    chiudiDialogModificaProgetto() {
      this.reloadLista();
      this.dialogModificaProgetto = false; 
    },
    apriProgetto(progetto) {
      if(progetto)
        this.$router.push('/schedaProgetto?_id=' + progetto['_id']);
    }
  },
};
</script>